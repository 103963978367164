import React, { Component } from "react";
import NavSideList from "./NavSideList";
import "./navside.scss";

export default class NavSide extends Component {
  state = {
    menuList: [
      // {
      //   text: "dashboard",
      //   path: "/",
      //   children: false,
      //   textIcon: "home"
      // },
      {
        text: "Pemeriksaan",
        childrenShow: false,
        textIcon: "edit",
        children: [
          { text: "List Data", path: "/pemeriksaan" },
          { text: "List Mohon", path: "/pemohon" },
        ]
      },
      // { text: "akun", path: "/akun", children: false },
      // {
      //   text: "keuangan",
      //   childrenShow: false,
      //   children: [{ text: "Inventory", path: "/keuangan/inventory" }]
      // },
      // {
      //   text: "Voucher Fisik",
      //   childrenShow: false,
      //   children: [
      //     { text: "Gosok", path: "/voucher/gosok" },
      //     { text: "Chip", path: "/voucher/chip" },
      //     { text: "Chip Group (Edit)", path: "/voucher/chip/group" },
      //     { text: "Total Stok", path: "/voucher/total-stok" }
      //   ]
      // },
      // {
      //   text: "Laporan",
      //   childrenShow: false,
      //   children: [
      //     { text: "Per Produk", path: "/laporan/produk" },
      //     { text: "Per Terminal", path: "/laporan/terminal" },
      //     { text: "Trx Terminal Per Cluster", path: "/laporan/cluster" }
      //   ]
      // },

      // {
      //   text: "Monitoring",
      //   childrenShow: false,
      //   children: [
      //     { text: "List", path: "/monitoring" },
      //     { text: "Download File", path: "/monitoring/download" }
      //   ]
      // }
    ]
  };

  handleShowChildren = id => {
    const menu = [...this.state.menuList];
    menu.map((item, index) => {
      if (index !== id) {
        return (item.childrenShow = false);
      }
    });
    menu[id].childrenShow = !menu[id].childrenShow;
    this.setState({ menuList: menu });
  };

  render() {
    return (
      <>
        <div className="navside-ew431">
          <ul className="parent">
            {this.state.menuList.map((menu, index) => (
              <NavSideList
                key={index}
                index={index}
                menu={menu}
                handleShowChildren={this.handleShowChildren}
                handleCloseMenuMobile={this.props.handleCloseMenuMobile}
              />
            ))}
          </ul>
        </div>
      </>
    );
  }
}
