export const getRupiah = (angka) => {
  var rupiah = '';
  var angkarev = angka.toString().split('').reverse().join('');
  for (var i = 0; i < angkarev.length; i++) if (i % 3 == 0) rupiah += angkarev.substr(i, 3) + '.';
  return rupiah.split('', rupiah.length - 1).reverse().join('');
}

export const getDateMonthYear = (date) => {
  return `${new Date(date).getUTCDate()}/${new Date(date).getUTCMonth()}/${new Date(date).getUTCFullYear()}`
}

export const dateFormatIso = (date) => {
  const dateformat = new Date(date);
  const dateformatstring = dateformat.getFullYear() + '-' + (dateformat.getMonth() + 1) + '-' + dateformat.getDate();
  return dateformatstring;
}

export const isInputNumber = (evt) => {
  var ch = String.fromCharCode(evt.which);
  if (!(/[0-9]/.test(ch))) {
    evt.preventDefault();
  }
}

export const isEmptyObject = (obj) => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key))
      return false;
  }
  return true;
}