import ls from 'local-storage';

export const errorHandler = (props, kode) => {
    if (kode === 401){
        alert("Silahkan Login Kembali");
        ls.remove('isLogin');
        ls.remove('token');
        props.history.push('/login');
        // setAnchorEl(null);
    }
    
    if (kode === 500){
        alert("Server Error");
    }
}