import http from "../services/axiosCommon";

class FileUploadService {
  upload(file, path, nik, onUploadProgress) {
    let formData = new FormData();

    console.log(file);

    formData.append("file", file, nik+'_'+path+'_'+file.name);

    if (path === 'ktp') {
      return http.post("/api/file/uploadKtp", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress,
      });
    } else {
      return http.post("/api/file/uploadSelfie", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress,
      });
    }

  }

  getFiles() {
    return http.get("/api/file/files");
  }
}

export default new FileUploadService();