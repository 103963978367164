import React from 'react';
import imgLogo from '../../../components/img/logompstore.svg';

const Drawer = () => {
  return (
    <>
      {/* logo */}
      <div className="row justify-content-center">
        <div className="col-7">
          <img src={imgLogo} alt="logo" className="pt-2 pb-4" style={{ width: '90%', margin: '10px 0 0 0' }} />
        </div>
      </div>
    </>
  )
}

export default Drawer
