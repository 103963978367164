import React, { useState, useEffect, createRef } from 'react'
import Loader from '../../components/loader/Loader'
import moment from 'moment'
import ReactExport from 'react-export-excel'
import ls from 'local-storage'
import { ExcelRenderer, OutTable } from "react-excel-renderer";
import axios from "axios";
import DownloadLink from "react-download-link";
import { Icon } from '@material-ui/core'
import { DateRangePicker } from 'react-date-range'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import localeId from 'date-fns/locale/id';
import { useHistory } from "react-router-dom";
import { dateFormatIso, getRupiah } from '../../helpers/helpers';
import Chart from 'chart.js/auto';
import { Bar, Line } from 'react-chartjs-2';
import { errorHandler } from '../../helpers/errorHandler'


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Dashboard = (props) => {

  const params = new URLSearchParams(props.location.search);
  const duaBulanSebelumnya = new Date();
  duaBulanSebelumnya.setMonth(duaBulanSebelumnya.getMonth() - 2);
  duaBulanSebelumnya.setDate(1);

  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [data, setData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [totalDataHariIni, setTotalDataHariIni] = useState(0);
  const [totalDataBulanIni, setTotalDataBulanIni] = useState(0);
  const [dataChart, setDataChart] = useState([{
    bulan: '',
    data: [0]
  }, {
    bulan: '',
    data: [0]
  }, {
    bulan: '',
    data: [0]
  }]);
  const [now, setNow] = useState(new Date().toLocaleString("id-ID", {
    dateStyle: 'full'
  }));

  // State untuk Transaksi
  const [transaksiPerjam, setTransaksiPerjam] = useState([]);
  const [transaksiHariIni, setTransaksiHariIni] = useState(0);
  const [transaksiBulanIni, setTransaksiBulanIni] = useState(0);
  const [transaksiPerBulan, setTransaksiPerBulan] = useState({
    bulan: [],
    data: []
  });
  const [labaPerBulan, setLabaPerBulan] = useState({
    bulan: [],
    data: []
  });
  const [omsetPerBulan, setOmsetPerBulan] = useState({
    bulan: [],
    data: []
  });
  const [transaksiPerHari, setTransaksiPerHari] = useState({
    data: [
      []
    ],
    laba: [
      []
    ],
    omset: [
      []
    ],
    label: [
      ""
    ]
  });

  // State untuk Transaksi
  const [totalReseller, setTotalReseller] = useState(0);
  const [resellerHariIni, setResellerHariIni] = useState(0);
  const [resellerBulanIni, setResellerBulanIni] = useState(0);
  const [resellerPerBulan, setResellerPerBulan] = useState({
    bulan: [],
    data: []
  });
  const [resellerPerHari, setResellerPerHari] = useState({
    data: [
      []
    ],
    label: [
      ""
    ]
  });

  let bulan = [];
  for (let i = 1; i <= 31; i++) {
    bulan.push(i);
  }

  const getDataTransaksi = async () => {
    // setIsLoading(true);
    await axios.get('/api/dashboard/transaksi', {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': ls.get('token')
      },
    })
      .then(function (response) {
        // handle success\
        let tempdataperhari = [];
        response.data.values.dataperjam.map(item => {
          tempdataperhari.push(item.TOTAL);
        });
        setTransaksiPerjam(tempdataperhari);

        setTransaksiHariIni(response.data.values.totalhariini);
        setTransaksiBulanIni(response.data.values.totalbulanini);

        let label1 = [];
        response.data.values.data.map(item => {
          label1.push(item.bulan);
        });
        setTransaksiPerBulan({
          bulan: label1.reverse(),
          data: [response.data.values.totalbulanmin2, response.data.values.totalbulanmin1, response.data.values.totalbulanini]
        });
        setLabaPerBulan({
          bulan: label1.reverse(),
          data: [response.data.values.lababulanmin2, response.data.values.lababulanmin1, response.data.values.lababulanini]
        });
        setOmsetPerBulan({
          bulan: label1.reverse(),
          data: [response.data.values.omsetbulanmin2, response.data.values.omsetbulanmin1, response.data.values.omsetbulanini]
        });

        // Perhari
        let label2 = [];
        let totalData2 = [];
        let labaPerhari = [];
        let omsetPerhari = [];
        let temp = [];
        let temp2 = []; 
        let temp3 = []; 
        response.data.values.data.map((item, index) => {
          label2.push(item.bulan);
          temp = [];
          temp2 = [];
          temp3 = [];
          item.data.map(sub => {
            temp.push(sub.TOTAL);
            temp2.push(sub.LABA);
            temp3.push(sub.OMSET);
          })
          totalData2.push(temp);
          labaPerhari.push(temp2);
          omsetPerhari.push(temp3);
        });
        // if (label2.length === 2) {
        //   label2.push('')
        // }
        setTransaksiPerHari({
          data: totalData2,
          laba: labaPerhari,
          omset: omsetPerhari,
          label: label2
        })

        // console.log(response);
        // console.log({
        //   data: totalData2,
        //   laba: labaPerhari,
        //   omset: omsetPerhari,
        //   label: label2
        // });

        // setIsLoading(false);
      })
      .catch(function (error) {
        // handle error
        // console.log(error);
        // setIsLoading(false);
        // if (error.response.status === 401){
        //   alert("Silahkan Login Kembali");
        //   ls.remove('isLogin');
        //   ls.remove('token');
        //   props.history.push('/login');
        //   // setAnchorEl(null);
        // }
        errorHandler(props, error.response.status);
      })
  };

  const getDataReseller = async () => {
    // setIsLoading(true);
    await axios.get('/api/dashboard/reseller', {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': ls.get('token')
      },
    })
      .then(function (response) {
        // handle success\
        setTotalReseller(response.data.values.total);

        setResellerHariIni(response.data.values.totalhariini);
        setResellerBulanIni(response.data.values.totalbulanini);

        let label1 = [];
        response.data.values.data.map(item => {
          label1.push(item.bulan);
        });
        setResellerPerBulan({
          bulan: label1.reverse(),
          data: [response.data.values.totalbulanmin2, response.data.values.totalbulanmin1, response.data.values.totalbulanini]
        });

        let label2 = [];
        let totalData2 = [];
        let temp = [];
        response.data.values.data.map((item, index) => {
          label2.push(item.bulan);
          temp = [];
          item.data.map(sub => {
            temp.push(sub.TOTAL);
          })
          totalData2.push(temp);
        });
        setResellerPerHari({
          data: totalData2,
          label: label2
        })

        console.log(response);
        // console.log(label1);

        // setIsLoading(false);
      })
      .catch(function (error) {
        // handle error
        // console.log(error);
        // setIsLoading(false);
        errorHandler(props, error.response.status);
      })
  };

  const fetchData = () => {
    setIsLoading(true);
    Promise.all([getDataTransaksi(), getDataReseller()]).then(() => {
      setIsLoading(false);
    })
  }

  useEffect(() => {
    fetchData();
  }, []);


  return (
    <>
      <Loader show={isLoading} />
      <Loader show={isDownloading} title="Downloading Data" />

      <div className="">

        <div className="row mb-3">
          <div className='col-12 col-md-12 p-2'>
            <h5 className='text-dark'>Transaksi</h5>
          </div>
          
          <div className='col-12 col-md-4 p-2 d-flex flex-column justify-content-around'>
            <div className='p-3 rounded bg-white drop-shadow h-100 mb-4 d-flex justify-content-center align-items-center'>
              <h6 className='m-0'>{now}</h6>
            </div>
            <div className='p-3 rounded bg-white drop-shadow h-100 mb-4 d-flex justify-content-between align-items-center'>
              <div className='text-muted' style={{fontSize: 12, fontWeight: 500}}>
                Total Transaksi Hari Ini
                <br/>
                <span className='h2 text-dark'>{getRupiah(transaksiHariIni)}</span>
              </div>
              <div className='rounded-icon'><i className="fa fa-calendar-o fa-lg text-white" aria-hidden="true"></i></div>
            </div>
            <div className='p-3 rounded bg-white drop-shadow h-100 d-flex justify-content-between align-items-center'>
              <div className='text-muted' style={{fontSize: 12, fontWeight: 500}}>
                Total Transaksi Bulan Ini
                <br/>
                <span className='h2 text-dark'>{getRupiah(transaksiBulanIni)}</span>
              </div>
              <div className='rounded-icon'><i className="fa fa-calendar fa-lg text-white" aria-hidden="true"></i></div>
            </div>
          </div>
          <div className='col-12 col-md-8 p-2 h-100'>
            <div className='p-3 rounded bg-white drop-shadow'>
              <Line
                datasetIdKey='id'
                height={'auto'}
                data={{
                  labels: ['00.00', '02.00', '04.00', '06.00', '08.00', '10.00', '12.00', '14.00', '16.00', '18.00', '20.00', '22.00', '24.00'],
                  datasets: [
                    {
                      id: 1,
                      label: 'Transaksi Hari Ini',
                      data: transaksiPerjam,
                      borderColor: '#f1b44c',
                      backgroundColor: '#f1b44c22',
                      tension: 0.5,
                      fill: true,
                    },
                  ],
                }}
              />
            </div>
          </div>


          <div className='col-12 col-md-6 p-2 d-flex flex-column justify-content-around'>
            <div className='p-3 rounded bg-white drop-shadow h-100'>
              <div className='row'>
                <div className='col-12 mb-3 text-center'><h5>Transaksi Perhari</h5></div>

                <div className='col-12'>
                  <Line
                    datasetIdKey='id'
                    height={'auto'}
                    data={{
                      labels: bulan,
                      datasets: [
                        {
                          id: 1,
                          label: transaksiPerHari.label[2],
                          data: transaksiPerHari.data[2],
                          borderColor: '#f46a6a',
                          backgroundColor: '#f46a6a22',
                          tension: 0.5,
                          fill: true,
                        },
                        {
                          id: 2,
                          label: transaksiPerHari.label[1],
                          data: transaksiPerHari.data[1],
                          borderColor: '#5dcfa5',
                          backgroundColor: '#5dcfa522',
                          tension: 0.5,
                          fill: true,
                        },
                        {
                          id: 3,
                          label: transaksiPerHari.label[0],
                          data: transaksiPerHari.data[0],
                          borderColor: '#556ee6',
                          backgroundColor: '#556ee622',
                          tension: 0.5,
                          fill: true,
                        },
                      ],
                    }}
                  />
                </div>

              </div>
            </div>
          </div>

          <div className='col-12 col-md-6 p-2 d-flex flex-column justify-content-around'>
            <div className='p-3 rounded bg-white drop-shadow h-100'>
              <div className='row'>
                <div className='col-12 mb-3 text-center'><h5>Transaksi Perbulan</h5></div>
                
                <div className='col-12'>
                  <Bar
                    datasetIdKey='id'
                    height={'auto'}
                    data={{
                      labels: transaksiPerBulan.bulan,
                      datasets: [
                        {
                          id: 1,
                          label: 'Total Transaksi',
                          data: transaksiPerBulan.data,
                          backgroundColor: ['#f46a6a', '#5dcfa5', '#556ee6'],
                          tension: 0.5,
                          fill: true,
                        },
                      ],
                    }}
                  />
                </div>

              </div>
            </div>
          </div>


          <div className='col-12 col-md-6 p-2 d-flex flex-column justify-content-around'>
            <div className='p-3 rounded bg-white drop-shadow h-100'>
              <div className='row'>
                <div className='col-12 mb-3 text-center'><h5>Laba Perhari</h5></div>

                <div className='col-12'>
                  <Line
                    datasetIdKey='id'
                    height={'auto'}
                    data={{
                      labels: bulan,
                      datasets: [
                        {
                          id: 1,
                          label: transaksiPerHari.label[2],
                          data: transaksiPerHari.laba[2],
                          borderColor: '#f46a6a',
                          backgroundColor: '#f46a6a22',
                          tension: 0.5,
                          fill: true,
                        },
                        {
                          id: 2,
                          label: transaksiPerHari.label[1],
                          data: transaksiPerHari.laba[1],
                          borderColor: '#5dcfa5',
                          backgroundColor: '#5dcfa522',
                          tension: 0.5,
                          fill: true,
                        },
                        {
                          id: 3,
                          label: transaksiPerHari.label[0],
                          data: transaksiPerHari.laba[0],
                          borderColor: '#556ee6',
                          backgroundColor: '#556ee622',
                          tension: 0.5,
                          fill: true,
                        },
                      ],
                    }}
                  />
                </div>

              </div>
            </div>
          </div>

          <div className='col-12 col-md-6 p-2 d-flex flex-column justify-content-around'>
            <div className='p-3 rounded bg-white drop-shadow h-100'>
              <div className='row'>
                <div className='col-12 mb-3 text-center'><h5>Laba Perbulan</h5></div>
                
                <div className='col-12'>
                  <Bar
                    datasetIdKey='id'
                    height={'auto'}
                    data={{
                      labels: labaPerBulan.bulan,
                      datasets: [
                        {
                          id: 1,
                          label: 'Laba',
                          data: labaPerBulan.data,
                          backgroundColor: ['#f46a6a', '#5dcfa5', '#556ee6'],
                          tension: 0.5,
                          fill: true,
                        },
                      ],
                    }}
                  />
                </div>

              </div>
            </div>
          </div>


          <div className='col-12 col-md-6 p-2 d-flex flex-column justify-content-around'>
            <div className='p-3 rounded bg-white drop-shadow h-100'>
              <div className='row'>
                <div className='col-12 mb-3 text-center'><h5>Omset Perhari</h5></div>

                <div className='col-12'>
                  <Line
                    datasetIdKey='id'
                    height={'auto'}
                    data={{
                      labels: bulan,
                      datasets: [
                        {
                          id: 1,
                          label: transaksiPerHari.label[2],
                          data: transaksiPerHari.omset[2],
                          borderColor: '#f46a6a',
                          backgroundColor: '#f46a6a22',
                          tension: 0.5,
                          fill: true,
                        },
                        {
                          id: 2,
                          label: transaksiPerHari.label[1],
                          data: transaksiPerHari.omset[1],
                          borderColor: '#5dcfa5',
                          backgroundColor: '#5dcfa522',
                          tension: 0.5,
                          fill: true,
                        },
                        {
                          id: 3,
                          label: transaksiPerHari.label[0],
                          data: transaksiPerHari.omset[0],
                          borderColor: '#556ee6',
                          backgroundColor: '#556ee622',
                          tension: 0.5,
                          fill: true,
                        },
                      ],
                    }}
                  />
                </div>

              </div>
            </div>
          </div>


          <div className='col-12 col-md-6 p-2 d-flex flex-column justify-content-around'>
            <div className='p-3 rounded bg-white drop-shadow h-100'>
              <div className='row'>
                <div className='col-12 mb-3 text-center'><h5>Omset Perbulan</h5></div>
                
                <div className='col-12'>
                  <Bar
                    datasetIdKey='id'
                    height={'auto'}
                    data={{
                      labels: omsetPerBulan.bulan,
                      datasets: [
                        {
                          id: 1,
                          label: 'Omset',
                          data: omsetPerBulan.data,
                          backgroundColor: ['#f46a6a', '#5dcfa5', '#556ee6'],
                          tension: 0.5,
                          fill: true,
                        },
                      ],
                    }}
                  />
                </div>

              </div>
            </div>
          </div>
        </div>

        <hr />

        <div className="row mb-3">
          <div className='col-12 col-md-12 p-2'>
            <h5 className='text-dark'>Reseller</h5>
          </div>
          
          <div className='col-12 col-md-12 p-2 d-flex flex-column flex-md-row justify-content-around'>
            <div className='p-3 rounded bg-white drop-shadow w-100 h-100 mb-4 d-flex justify-content-between align-items-center'>
              <div className='text-muted' style={{fontSize: 12, fontWeight: 500}}>
                Total Jumlah Reseller
                <br/>
                <span className='h2 text-dark'>{getRupiah(totalReseller)}</span>
              </div>
              <div className='rounded-icon'><i className="fa fa-calendar-o fa-lg text-white" aria-hidden="true"></i></div>
            </div>
            <div className='p-3 rounded bg-white drop-shadow w-100 h-100 mx-3 mb-4 d-flex justify-content-between align-items-center'>
              <div className='text-muted' style={{fontSize: 12, fontWeight: 500}}>
                Reseller Hari Ini
                <br/>
                <span className='h2 text-dark'>{getRupiah(resellerHariIni)}</span>
              </div>
              <div className='rounded-icon'><i className="fa fa-calendar-o fa-lg text-white" aria-hidden="true"></i></div>
            </div>
            <div className='p-3 rounded bg-white drop-shadow w-100 h-100 d-flex justify-content-between align-items-center'>
              <div className='text-muted' style={{fontSize: 12, fontWeight: 500}}>
                Reseller Bulan Ini
                <br/>
                <span className='h2 text-dark'>{getRupiah(resellerBulanIni)}</span>
              </div>
              <div className='rounded-icon'><i className="fa fa-calendar fa-lg text-white" aria-hidden="true"></i></div>
            </div>
          </div>

          <div className='col-12 col-md-6 p-2 d-flex flex-column justify-content-around'>
            <div className='p-3 rounded bg-white drop-shadow h-100 mb-4'>
              <div className='row'>
                <div className='col-12 mb-3 text-center'><h5>Reseller Perhari</h5></div>

                <div className='col-12'>
                  <Line
                    datasetIdKey='id'
                    height={'auto'}
                    data={{
                      labels: bulan,
                      datasets: [
                        {
                          id: 1,
                          label: resellerPerHari.label[2],
                          data: resellerPerHari.data[2],
                          borderColor: '#f46a6a',
                          backgroundColor: '#f46a6a22',
                          tension: 0.5,
                          fill: true,
                        },
                        {
                          id: 2,
                          label: resellerPerHari.label[1],
                          data: resellerPerHari.data[1],
                          borderColor: '#5dcfa5',
                          backgroundColor: '#5dcfa522',
                          tension: 0.5,
                          fill: true,
                        },
                        {
                          id: 3,
                          label: resellerPerHari.label[0],
                          data: resellerPerHari.data[0],
                          borderColor: '#556ee6',
                          backgroundColor: '#556ee622',
                          tension: 0.5,
                          fill: true,
                        },
                      ],
                    }}
                  />
                </div>

              </div>
            </div>
          </div>
          <div className='col-12 col-md-6 p-2 d-flex flex-column justify-content-around'>
            <div className='p-3 rounded bg-white drop-shadow h-100 mb-4'>
              <div className='row'>
                <div className='col-12 mb-3 text-center'><h5>Reseller Perbulan</h5></div>
                
                <div className='col-12'>
                  <Bar
                    datasetIdKey='id'
                    height={'auto'}
                    data={{
                      labels: resellerPerBulan.bulan,
                      datasets: [
                        {
                          id: 1,
                          label: 'Total Reseller',
                          data: resellerPerBulan.data,
                          backgroundColor: ['#f46a6a', '#5dcfa5', '#556ee6'],
                          tension: 0.5,
                          fill: true,
                        },
                      ],
                    }}
                  />
                </div>

              </div>
            </div>
          </div>
          

        </div>
      </div>
    </>
  )
}

export default React.memo(Dashboard)
