import React, { Component } from 'react'
import ls from 'local-storage'
import utm from "../../components/img/logompstore.svg";
import { postLogin } from '../../services/auth';
import Cookies from 'universal-cookie';

export default class Login extends Component {

  state = {
    isLoading: false,
    username: '',
    password: ''
  }

  onChangeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  loginHandler = (e) => {
    e.preventDefault();
    if (this.state.username === '' || this.state.password === '') {
      alert('isi data');
      return false;
    } else {
      this.setState({ isLoading: true });
      let data = {
        username: this.state.username,
        password: this.state.password,
      }
      postLogin(data)
      .then((responseJson) => {
        console.log(responseJson)
            this.setState({
              isLoading: false
            });
            let cookie = '__bpjph_ct='+responseJson.payload.token+';'+'__bpjph_rt='+responseJson.payload.refreshToken;
            console.log(cookie);
            ls.set('isLogin', true)
            ls.set('token', responseJson.payload.token)
            ls.set('userid', responseJson.payload.userid)
            ls.set('role', responseJson.payload.role)
            ls.set('nama', responseJson.payload.name)
            ls.set('__bpjph_ct', responseJson.payload.token)
            ls.set('__bpjph_rt', responseJson.payload.refreshToken)
            ls.set('Cookie', cookie);
            alert('Login Success');
            const cookies = new Cookies();
            cookies.set('__bpjph_ct=', responseJson.payload.token, { path: '/', httpOnly:'true', secure:false });
            cookies.set('__bpjph_rt=', responseJson.payload.refreshToken, { path: '/', httpOnly:'true', secure:false });
            cookies.set('__bpjph_ct=', responseJson.payload.token, { path: '/' , domain:'103.7.14.55', httpOnly:'true', secure:false});
            cookies.set('__bpjph_rt=', responseJson.payload.refreshToken, { path: '/' , domain:'103.7.14.55', httpOnly:'true', secure:false});
            cookies.set('Cookie', cookie);
            this.props.history.push('/pemeriksaan');
          })
          .catch((error) => {
            console.log(error)
            alert('Login failed');
            this.setState({ isLoading: false });
          });
      // fetch('http://103.7.14.55/auth/signin', {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      //   body: JSON.stringify({
      //     userid: this.state.username,
      //     password: this.state.password
      //   })
      // })
      //   .then((response) => {
      //     if (response.ok) { return response.json() }
      //     else { throw Error(response.statusText) }
      //   })
      //   .then((responseJson) => {
      //     this.setState({
      //       isLoading: false
      //     });
      //     ls.set('isLogin', true)
      //     ls.set('token', responseJson.payload.token)
      //     ls.set('userid', responseJson.payload.userid)
      //     ls.set('role', responseJson.payload.role)
      //     ls.set('nama', responseJson.payload.name)
      //     alert('Login Success');
      //     this.props.history.push('/');
      //   })
      //   .catch((error) => {
      //     console.log(error)
      //     alert('Login failed');
      //     this.setState({ isLoading: false });
      //   });
    }
  }

  render() {
    return (
      <>
        <div className="container page-login text-center">
            <div className="col-md-5 text-center">
              <img src={utm} alt="..." width="190"/>
              <h5 className="mb-4">Silahkan Login</h5>
              <form onSubmit={this.loginHandler} method="POST">
                <div className="form-group">
                  <input type="text" name="username" className="form-control" placeholder='Username' onChange={this.onChangeHandler} />
                </div>
                <div className="form-group">
                  <input type="password" name="password" className="form-control" placeholder='Password' onChange={this.onChangeHandler} />
                </div>
                <div className="form-group">
                  <button type="submit" className="btn btn-info btn-block px-4">
                    Login
                  </button>
                </div>
              </form>
            </div>
        </div>
      </>
    )
  }
}
