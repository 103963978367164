import React, { useState, useEffect, createRef } from 'react'
import Loader from '../../components/loader/Loader'
import moment from 'moment'
import ReactExport from 'react-export-excel'
import ls from 'local-storage'
import { ExcelRenderer, OutTable } from "react-excel-renderer";
import axios from "axios";
import DownloadLink from "react-download-link";
import { Icon } from '@material-ui/core'
import { DateRangePicker } from 'react-date-range'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import localeId from 'date-fns/locale/id';
import { useHistory } from "react-router-dom";
import { dateFormatIso, getRupiah } from '../../helpers/helpers';
import Chart from 'chart.js/auto';
import { Line } from 'react-chartjs-2';
import fileDownload from 'js-file-download'
import { errorHandler } from '../../helpers/errorHandler'


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Reseller = (props) => {

  const params = new URLSearchParams(props.location.search);
  const duaBulanSebelumnya = new Date();
  duaBulanSebelumnya.setMonth(duaBulanSebelumnya.getMonth() - 2);
  duaBulanSebelumnya.setDate(1);
  const bulanIniStart = new Date();
  bulanIniStart.setDate(1);
  
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [data, setData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [totalDataHariIni, setTotalDataHariIni] = useState(0);
  const [totalDataBulanIni, setTotalDataBulanIni] = useState(0);
  const [dataChart, setDataChart] = useState([{bulan:'', data: [0]},{bulan:'', data: [0]},{bulan:'', data: [0]}]);
  const [dataexcel, setDataexcel] = useState([]);
  const [searchText, setSearchText] = useState(params.has('keyword') ? params.get('keyword') : '');
  const [startDate, setStartDate] = useState(params.has('startdate') ? new Date(params.get('startdate')) : bulanIniStart);
  const [endDate, setEndDate] = useState(params.has('enddate') ? new Date(params.get('enddate')) : new Date());
  const [selectionRange, setselectionRange] = useState({
    startDate: params.has('startdate') ? new Date(params.get('startdate')) : bulanIniStart,
    endDate: params.has('enddate') ? new Date(params.get('enddate')) : new Date(),
  });
  
  let bulan = [];
  for (let i = 1; i <= 31; i++) {
    bulan.push(i);
  }
  
  let history = useHistory();

  const downloadExcelRef = createRef();
  
  const [page, setPage] = useState(1);
  const clearPage = () => setPage(1);
  const handleChangePageNumber = (number) => {
    setPage(number);
    window.scrollTo(0, 0);
  };

  // get data voucher template
  const getFormatData = (startdate, enddate) => {
    setIsLoading(true);
    // getData(`/api/mutasibank?keyword=${text}&startdate=${startdate}&enddate=${enddate}&status=${status}&page=${param_page}`)
    // getData(`https://run.mocky.io/v3/72ec1a12-ed0c-4c3a-90dd-50fbc4a44263`)
    //   .then(res => {
      //     if (res.values.data.length !== 0) {
        //       setData(res.values.data);
        //       setTotalData(res.values.total);
        //     }
        //     setIsLoading(false);
        //     console.log(res);
        //   })
    //   .catch(err => {
    //     console.log(err);
    //     setIsLoading(false);
    //   })

    // axios.get('https://run.mocky.io/v3/b2ded32d-52d1-40d8-b07a-3a17c77c4e70')
    axios.get(`/api/reseller?startdate=${startdate}&enddate=${enddate}`, {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': ls.get('token')
      },
    })
    .then(function (response) {
      // handle success
      setData(response.data.values.data)
      setTotalData(response.data.values.total);
      // console.log(response);
      setIsLoading(false);
    })
    .catch(function (error) {
      // handle error
      // console.log(error);
      setIsLoading(false);
      errorHandler(props, error.response.status);
    })
  }
  
  const getDataChart = () => {
    setIsLoading(true);
    axios.get('/api/reseller/dataperhari', {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': ls.get('token')
      },
    })
    .then(function (response) {
      // handle success\
      setDataChart(response.data.values.data);
      setTotalDataBulanIni(response.data.values.totalbulanini)
      setTotalDataHariIni(response.data.values.totalhariini)
      // console.log(response.data);
      // console.log(dataChart);
      setIsLoading(false);
    })
    .catch(function (error) {
      // handle error
      // console.log(error);
      setIsLoading(false);
      errorHandler(props, error.response.status);
    })
  };

  const fetchData = () => {
    const paramstartdate = params.has('startdate') ? params.get('startdate') : dateFormatIso(startDate);
    const paramenddate = params.has('enddate') ? params.get('enddate') : dateFormatIso(endDate);
    getFormatData(paramstartdate, paramenddate);
    getDataChart();
  }
  
  useEffect(() => {
    fetchData();
  }, [])

  // let selectionRange = {
  //   startDate: new Date(),
  //   endDate: new Date(),
  // };


  function dateFormat(date) {
    const months = [
      'Januari',
      'Februari',
      'Maret',
      'April',
      'Mei',
      'Juni',
      'Juli',
      'Agustus',
      'September',
      'Oktober',
      'November',
      'Desember',
    ];
    const d = date;
    return d.getDate() + ' ' + months[d.getMonth()] + ' ' + d.getFullYear();
  }

  const filter = () => {
    clearPage();
    getFormatData(dateFormatIso(selectionRange.startDate), dateFormatIso(selectionRange.endDate));
    history.push({
      pathname: '/reseller',
      search: `?startdate=${dateFormatIso(selectionRange.startDate)}&enddate=${dateFormatIso(selectionRange.endDate)}`
    });
    setStartDate(selectionRange.startDate);
    setEndDate(selectionRange.endDate);
  };
  
  const clearFilter = () => {
    setSearchText('');
    setStartDate(bulanIniStart);
    setEndDate(new Date());
    setselectionRange({
      startDate: bulanIniStart,
      endDate: new Date(),
    });
    clearPage();
    getFormatData(dateFormatIso(bulanIniStart), dateFormatIso(new Date()))
    history.push({
      pathname: '/reseller'
    });
    // console.log(startDate);
  };

  const getDataArrayPerbulan = (data) => {
    let temp = [];
    data.data.map(item => {
      temp.push(item.TOTAL);
    });
    return temp;
  };

  function downloadExcel() {
    setDataexcel([]);
    setIsDownloading(true);
    axios.get(`/api/reseller/download?startdate=${dateFormatIso(startDate)}&enddate=${dateFormatIso(endDate)}`, {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': ls.get('token')
      },
    })
    .then(function (response) {
      // handle success
      setDataexcel(response.data.values.data)
      // console.log(response);
      setIsDownloading(false);
      // excelClick();
    })
    .catch(function (error) {
      // handle error
      // console.log(error);
      setIsDownloading(false);
      errorHandler(props, error.response.status);
    })
  }

  function downloadExcel2() {
    // setDataexcel([]);
    setIsDownloading(true);
    axios.get(`/api/reseller/downloadExcel?startdate=${dateFormatIso(startDate)}&enddate=${dateFormatIso(endDate)}`, {
      headers: {
        // 'Content-Type': 'blob',
        'x-access-token': ls.get('token')
      },
      responseType: 'arraybuffer',
    })
    .then(function (response) {
      // handle success
      // setDataexcel(response.data.values.data)
      // console.log(response);
      fileDownload(response.data, 'reseller.xlsx');
      // let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }),
      // url = window.URL.createObjectURL(blob)

      // window.open(url)
      setIsDownloading(false);
      // excelClick();
    })
    .catch(function (error) {
      // handle error
      // console.log(error);
      setIsDownloading(false);
      errorHandler(props, error.response.status);
    })
  }

  useEffect(() => {
    if(dataexcel.length !== 0){
      downloadExcelRef.current.click();
    }
  }, [dataexcel]);

  return (
    <>
      <Loader show={isLoading} />
      <Loader show={isDownloading} title="Downloading Data" />

      {/* Modal */}
      <div className="modal fade" id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document" style={{minWidth: 'auto', maxWidth: 'fit-content'}}>
          <div className="modal-content">
            <div className="modal-body">
              <DateRangePicker locale={localeId} ranges={[selectionRange]} onChange={(DateRange) => setselectionRange(DateRange.range1)} maxDate={new Date()} minDate={new Date('2018-02-27')} editableDateInputs={true} dateDisplayFormat="d MMMM yyyy" />
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>

      <div className="">

        <div className="row mb-3">
          <div className='col-12 col-md-12 p-2'>
            <h5 className='text-dark'>Reseller</h5>
          </div>
          
          <div className='col-12 col-md-4 p-2 d-flex flex-column justify-content-around'>
            <div className='p-3 rounded bg-white drop-shadow h-100 mb-4 d-flex justify-content-center align-items-center'>
              <h6 className='m-0'>{dateFormat(startDate) + ' - ' + dateFormat(endDate)}</h6>
            </div>
            <div className='p-3 rounded bg-white drop-shadow h-100 mb-4 d-flex justify-content-between align-items-center'>
              <div className='text-muted' style={{fontSize: 12, fontWeight: 500}}>
                Total Data
                <br/>
                <span className='h2 text-dark'>{getRupiah(totalData)}</span>
              </div>
              <div className='rounded-icon'><i className="fa fa-database fa-lg text-white" aria-hidden="true"></i></div>
            </div>
            <div className='p-3 rounded bg-white drop-shadow h-100 mb-4 d-flex justify-content-between align-items-center'>
              <div className='text-muted' style={{fontSize: 12, fontWeight: 500}}>
                Total Reseller Hari Ini
                <br/>
                <span className='h2 text-dark'>{getRupiah(totalDataHariIni)}</span>
              </div>
              <div className='rounded-icon'><i className="fa fa-calendar-o fa-lg text-white" aria-hidden="true"></i></div>
            </div>
            <div className='p-3 rounded bg-white drop-shadow h-100 d-flex justify-content-between align-items-center'>
              <div className='text-muted' style={{fontSize: 12, fontWeight: 500}}>
                Total Reseller Bulan Ini
                <br/>
                <span className='h2 text-dark'>{getRupiah(totalDataBulanIni)}</span>
              </div>
              <div className='rounded-icon'><i className="fa fa-calendar fa-lg text-white" aria-hidden="true"></i></div>
            </div>
          </div>
          <div className='col-12 col-md-8 p-2 h-100'>
            <div className='p-3 rounded bg-white drop-shadow'>
              <Line
                datasetIdKey='id'
                height={'auto'}
                data={{
                  labels: bulan,
                  datasets: [
                    {
                      id: 1,
                      label: dataChart[2].bulan,
                      data: getDataArrayPerbulan(dataChart[2]),
                      borderColor: '#f46a6a',
                      backgroundColor: '#f46a6a22',
                      tension: 0.5,
                      fill: true,
                    },
                    {
                      id: 2,
                      label: dataChart[1].bulan,
                      data: getDataArrayPerbulan(dataChart[1]),
                      borderColor: '#5dcfa5',
                      backgroundColor: '#5dcfa522',
                      tension: 0.5,
                      fill: true,
                    },
                    {
                      id: 3,
                      label: dataChart[0].bulan,
                      data: getDataArrayPerbulan(dataChart[0]),
                      borderColor: '#556ee6',
                      backgroundColor: '#556ee622',
                      tension: 0.5,
                      fill: true,
                    },
                  ],
                }}
              />
            </div>
          </div>


          {/* <div className='col-12 col-md-6 p-2 d-flex flex-column justify-content-around'>
            <div className='p-3 rounded bg-white drop-shadow h-100 mb-4'>
              <div className='row'>
                <div className='col-12 mb-3 text-center'><h5>Januari 2022</h5></div>
                <div className='col-8 mb-3 d-flex align-items-center'>
                  <i className="fa fa-database fa-2x mr-2" style={{color: '#5dcfa5'}} aria-hidden="true"></i>
                  <div>
                    <h5 className='mb-0'>{totalDataBulanIni}</h5>
                    <span className='text-muted' style={{fontSize: 12}}>Total Data</span>
                  </div>
                </div>
                <div className='col-4 text-center mb-3'>
                  <button type="button" className="btn btn-success btn-sm border-0" style={{backgroundColor: '#5dcfa5'}}>
                    <i className="fa fa-file-excel-o" aria-hidden="true"></i>
                    <br />
                    Download Data
                  </button>
                </div>
                <div className='col-12'>
                  <Line
                    datasetIdKey='id'
                    height={'auto'}
                    data={{
                      labels: bulan,
                      datasets: [
                        {
                          id: 1,
                          label: dataChart[1].bulan,
                          data: dataChart[1].data,
                          borderColor: '#5dcfa5',
                          backgroundColor: '#5dcfa5',
                        },
                      ],
                    }}
                  />
                </div>

              </div>
            </div>
          </div>
          <div className='col-12 col-md-6 p-2 d-flex flex-column justify-content-around'>
            <div className='p-3 rounded bg-white drop-shadow h-100 mb-4'>
              <div className='row'>
                <div className='col-12 mb-3 text-center'><h5>Februari 2022</h5></div>
                <div className='col-8 mb-3 d-flex align-items-center'>
                  <i className="fa fa-database fa-2x mr-2" style={{color: '#556ee6'}} aria-hidden="true"></i>
                  <div>
                    <h5 className='mb-0'>{totalDataBulanIni}</h5>
                    <span className='text-muted' style={{fontSize: 12}}>Total Data</span>
                  </div>
                </div>
                <div className='col-4 text-center mb-3'>
                  <button type="button" className="btn btn-success btn-sm border-0" style={{backgroundColor: '#556ee6'}}>
                    <i className="fa fa-file-excel-o" aria-hidden="true"></i>
                    <br />
                    Download Data
                  </button>
                </div>
                <div className='col-12'>
                  <Line
                    datasetIdKey='id'
                    height={'auto'}
                    data={{
                      labels: bulan,
                      datasets: [
                        {
                          id: 1,
                          label: dataChart[2].bulan,
                          data: dataChart[2].data,
                          borderColor: '#556ee6',
                          backgroundColor: '#556ee6',
                        },
                      ],
                    }}
                  />
                </div>

              </div>
            </div>
          </div> */}


          <div className='col-12 col-md-12 p-2'>
            <div className='p-3 rounded bg-white drop-shadow'>
              <div className='d-flex mb-2 flex-column flex-md-row'>
              
                {/* <input type="text" style={{fontSize: 12, width: '210px'}} className="form-control bg-white" readOnly id="orderid-input" data-toggle="modal" data-target="#exampleModal" value={dateFormat(selectionRange.startDate) + ' - ' + dateFormat(selectionRange.endDate)} /> */}


                <ExcelFile filename="Reseller" element={<button className='btn btn-success mr-auto d-none mb-2' ref={downloadExcelRef} style={{fontSize: 12}}><i className="fa fa-file-excel-o" aria-hidden="true"></i> Download Excel</button>}>
                  <ExcelSheet data={dataexcel} name="Reseller">
                    <ExcelColumn label="Waktu Join" value="WaktuJoin" />
                    <ExcelColumn label="ID Reseller" value="IDRESELLER" />
                    <ExcelColumn label="Nama" value="NAMARESELLER" />
                    <ExcelColumn label="Alamat" value="alamatreseller" />
                    <ExcelColumn label="Saldo" value="saldo" />
                    <ExcelColumn label="Tambahan Harga Pribadi" value="TambahanHargaPribadi" />
                    <ExcelColumn label="Tambahan Harga Upline" value="tambahanhargaupline" />
                    <ExcelColumn label="Pendapatan DownLine" value="PendapatanDownLine" />
                    <ExcelColumn label="Aktivitas Terakhir" value="AktivitasTerakhir" />
                    <ExcelColumn label="Alias" value="alias" />
                    <ExcelColumn label="Poin" value="poin" />
                    <ExcelColumn label="Nama Pemilik" value="namapemilik" />
                    <ExcelColumn label="No KTP" value="noktp" />
                    <ExcelColumn label="Tgl Lahir" value="tgllahir" />
                    <ExcelColumn label="Ibu Kandung" value="ibukandung" />
                    <ExcelColumn label="Telp" value="telp" />
                    <ExcelColumn label="Email" value="email" />
                    <ExcelColumn label="Provinsi" value="province_name" />
                    <ExcelColumn label="Kota" value="city_name" />
                    <ExcelColumn label="Kecamatan" value="district_name" />
                    <ExcelColumn label="Kode POS" value="kodepos" />
                  </ExcelSheet>
                </ExcelFile>

                <div className='mr-auto d-flex flex-md-row flex-column'>
                  {/* <input type="text" value={searchText} onChange={(e) => setSearchText(e.target.value)} style={{width: '200px', fontSize: 12}} className="form-control bg-white mr-2 mb-2" placeholder="Search..." /> */}

                  <div className="input-group mb-2" style={{width: '300px'}}>
                    <input type="text" className="form-control bg-white" style={{fontSize: 12}} readOnly data-toggle="modal" data-target="#exampleModal" value={dateFormat(selectionRange.startDate) + ' - ' + dateFormat(selectionRange.endDate)} />
                    {params.has('startdate') && (
                      <div className="input-group-append">
                        <button className="btn btn-outline-danger" onClick={() => clearFilter()} style={{fontSize: 12}}><i className="fa fa-refresh" aria-hidden="true"></i></button>
                      </div>
                    )}
                    <div className="input-group-append">
                      <button className="btn btn-outline-primary" onClick={() => filter()} style={{fontSize: 12}}>Filter</button>
                    </div>
                  </div>
                </div>
                
                {/* <button 
                  className='btn btn-success mb-2' 
                  onClick={() => {
                    downloadExcel();
                  }} 
                  style={{fontSize: 12}}>
                    <i className="fa fa-file-excel-o" aria-hidden="true"></i> Download Excel
                </button> */}
                <button 
                  className='btn btn-success mb-2' 
                  onClick={() => {
                    downloadExcel2();
                  }} 
                  style={{fontSize: 12}}>
                    <i className="fa fa-file-excel-o" aria-hidden="true"></i> Download Excel
                </button>

              </div>

              <table className="table table-nowrap">
                <thead className="table-default">
                    <tr>
                        <th scope="col" className='border-0 text-dark' style={{width: '100px'}}>ID Reseller</th>
                        <th scope="col" className='border-0 text-dark'>Nama</th>
                        <th scope="col" className='border-0 text-dark'>Email</th>
                        <th scope="col" className='border-0 text-dark'>Waktu Join</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item, index) => {
                      return (
                        <tr key={index}>
                            <td className='font-weight-bold'>
                                {item.IDRESELLER}
                            </td>
                            <td>
                                <h6 className="mb-1">{item.NAMARESELLER}</h6>
                                <p className="text-muted mb-0" style={{fontSize: 12}}>{item.city_name}</p>
                            </td>
                            <td>{item.email}</td>
                            <td>
                                {dateFormat(new Date(item.WaktuJoin))}
                            </td>
                        </tr>
                      )
                    })}
                </tbody>
              </table>
              <p>Total Data {getRupiah(totalData)}, Silahkan download data</p>
              {/* <div className="col">
                {totalData > 100 ? (
                  <Pagination
                    page={page}
                    url="/reseller"
                    getData={getFormatData}
                    totalPage={Math.ceil(totalData / data.length)}
                    handleChangePageNumber={handleChangePageNumber}
                  />
                ) : null}
              </div> */}
            </div>
          </div>
          

        </div>
      </div>
    </>
  )
}

export default React.memo(Reseller)
