import React, { useState, useEffect, createRef } from 'react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import Combobox from "react-widgets/Combobox";
import utm from "../../components/img/logo.png";
import UploadImages from '../../components/uploadFile/uploadFile'

const NotFound = (props) => {

  return (
    <>

      <div className="container pt-5 text-center d-flex justify-content-center page-login">
        <div className="col-md-5 text-center">
          <img src={utm} width={'150'} className='mb-5' />
          <h1 className="mb-4 text-secondary font-weight-normal text-uppercase">Error 404</h1>
          <h3 className="mb-4 text-secondary font-italic font-weight-normal text-capitalize">Page Not Found</h3>
        </div>
      </div>
    </>
  )
}

export default React.memo(NotFound)