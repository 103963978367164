import React, { Component } from "react";
import UploadService from "../../services/fileUploadServices";


export default class UploadImages extends Component {
    timer;
    state = {
        currentFile: undefined,
        previewImage: undefined,
        progress: 0,
        message: "",
        title: this.props.title,
        nik: this.props.nik,
        imageInfos: [],
        onChange: this.props.onChange,
    }

    handleName() {
        return this.state.currentFile.name;
    }

    componentDidMount = () => {
        UploadService.getFiles().then((response) => {
            this.setState({
                imageInfos: response.data,
            });
        });
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    selectFile = (event) => {
        this.setState({
            currentFile: event.target.files[0],
            previewImage: URL.createObjectURL(event.target.files[0]),
            progress: 0,
            message: "success Upload image " + this.state.title,
        });
        this.timer = setTimeout(() => this.upload(), 1000);
        if(typeof this.state.onChange === 'function'){
            // call the callback passing in whatever parameters you decide
            // in this simple case just sending numeric value
            this.state.onChange(event.target.files[0].name)
         }    
    }

    upload = () => {
        this.setState({
            progress: 0,
        });

        UploadService.upload(this.state.currentFile, this.state.title, this.state.nik, (event) => {
            this.setState({
                progress: Math.round((100 * event.loaded) / event.total),
            });
        })
            .then((response) => {
                this.setState({
                    message: response.data.message,
                });
                return UploadService.getFiles();
            })
            .then((files) => {
                this.setState({
                    imageInfos: files.data,
                });
            })
            .catch((err) => {
                console.log(err);
                this.setState({
                    progress: 0,
                    message: "Could not upload the image!",
                    currentFile: undefined,
                });
            });
    }

    render() {
        const {
            currentFile,
            previewImage,
            progress,
            message,
            imageInfos,
        } = this.state;

        return (
            <div>
                <div className="row">
                    <div className="col-8">
                        <label className="btn btn-default p-0">
                            <input type="file" accept="image/*" onChange={this.selectFile} required />
                        </label>
                    </div>
                    {/* 
                    <div className="col-4">
                        <button
                            className="btn btn-success btn-sm"
                            disabled={!currentFile}
                            onClick={this.upload}
                        >
                            Upload
                        </button>
                    </div> */}
                </div>

                {currentFile && (
                    <div className="progress my-3">
                        <div
                            className="progress-bar progress-bar-info progress-bar-striped"
                            role="progressbar"
                            aria-valuenow={progress}
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style={{ width: progress + "%" }}
                        >
                            {progress}%
                        </div>
                    </div>
                )}

                {previewImage && (
                    <div>
                        <img className="preview" width={100} src={previewImage} alt="" />
                    </div>
                )}

                {message && (
                    <div className="alert alert-secondary mt-3" role="alert">
                        {message}
                    </div>
                )}

                {/* <div className="card mt-3">
                    <div className="card-header">List of Files</div>
                    <ul className="list-group list-group-flush">
                        {imageInfos &&
                            imageInfos.map((img, index) => (
                                <li className="list-group-item" key={index}>
                                    <a href={img.url}>{img.name}</a>
                                </li>
                            ))}
                    </ul>
                </div> */}
            </div>
        );

    }
}