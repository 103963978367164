import React from 'react'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { withRouter } from "react-router-dom";

const Breadcrumb = (props) => {
  const url = props.location.pathname;
  if (url === '/admin/dashboard') {
    return null
  } else {
    return (
      <div className="border-bottom px-3 mb-3">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="d-none d-lg-block">
                <div className="d-flex align-items-center" style={{ height: '49.9px' }}>
                  <div className="pr-2">Dashboard</div>
                  <div className="pr-2"><ArrowRightAltIcon /></div>
                  <div className="pr-2 text-gray-600">Page</div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 text-lg-right">
              <div className="py-3 py-lg-0">
                Senin, 29 Sept 2019
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(Breadcrumb)
