import axios from "axios";

// export const postLogin = async (data) => {
//     const URL = 'auth/signin';
//     const res = axios({
//       method: 'POST',
//       url: URL,
//       headers: {
//         'Content-Type': 'application/json',
//         'Accept' : '*/*',
//         'Accept-Encoding' : 'gzip, deflate, br',
//         'Connection' : 'keep-alive',
//       },
//       data: {
//         userid: data.username,
//         password: data.password
//       },
//       withCredentials: true
//     })
//     .then(function (response) {
//       let pushRes = response;
//       return pushRes;
//     })
//     .catch(function (error) {
//       let pushRes = error.response;
//       return pushRes;
//     });
//     return await res;
//   }

  export const postLogin = async (data) => {
    const URL = 'auth/signin';
    const res = fetch(URL,{
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Accept' : '*/*',
      },
      credentials: 'include',
      body: JSON.stringify({
        userid: data.username,
        password: data.password
      }),
    })
    .then(function (response) {
      let pushRes = response.json();
      return pushRes;
    })
    .catch(function (error) {
      let pushRes = error.response;
      return pushRes;
    });
    return await res;
  }

export const postLogout = async () => {
  const URL = 'auth/logout';
  const res = axios({
    method: 'POST',
    url: URL,
    headers: {
      'Content-Type': 'application/json',
      'Accept': '*/*',
      'Accept-Encoding': 'gzip, deflate, br',
      'Connection': 'keep-alive',
    },
    withCredentials: true,
  })
    .then(function (response) {
      let pushRes = response;
      return pushRes;
    })
    .catch(function (error) {
      let pushRes = error.response;
      return pushRes;
    });
  return await res;
}