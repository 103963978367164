import React, { useState, useEffect, createRef } from 'react'
import Loader from '../../components/loader/Loader'
import moment from 'moment'
import ReactExport from 'react-export-excel'
import ls from 'local-storage'
import { ExcelRenderer, OutTable } from "react-excel-renderer";
import axios from "axios";
import DownloadLink from "react-download-link";
import { Icon } from '@material-ui/core'
import { DateRangePicker } from 'react-date-range'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import localeId from 'date-fns/locale/id';
import { useHistory } from "react-router-dom";
import { dateFormatIso, getRupiah } from '../../helpers/helpers';
import Chart from 'chart.js/auto';
import { Line } from 'react-chartjs-2';
import fileDownload from 'js-file-download'
import { errorHandler } from '../../helpers/errorHandler'
import { dataList } from '../../services/checking'
import Combobox from "react-widgets/Combobox";
import "react-widgets/scss/styles.scss";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Pemeriksaan = (props) => {

  const params = new URLSearchParams(props.location.search);
  const duaBulanSebelumnya = new Date();
  duaBulanSebelumnya.setMonth(duaBulanSebelumnya.getMonth() - 2);
  duaBulanSebelumnya.setDate(1);
  const bulanIniStart = new Date();
  bulanIniStart.setDate(1);

  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [data, setData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [dataexcel, setDataexcel] = useState([]);

  let history = useHistory();

  const downloadExcelRef = createRef();
  const status = ["Ajuan", "Penetapan Biaya", "Diperiksa", "Selesai"]
  const [selectedStatus, setSelectedStatus] = useState('Ajuan');

  // get data voucher template
  const getFormatData = (status) => {
    setIsLoading(true);
    dataList(status)
      .then((responseJson) => {
        // handle success
        console.log("data : ", responseJson)
        // console.log(response);
        setIsLoading(false);
      })
      .catch((error) => {
        // handle error
        // console.log(error);
        setIsLoading(false);
        errorHandler(props, error);
      })
  }

  const fetchData = () => {
    getFormatData(status);
  }

  useEffect(() => {
    fetchData();
  }, [])

  //   const filter = () => {
  //     clearPage();
  //     getFormatData(dateFormatIso(selectionRange.startDate), dateFormatIso(selectionRange.endDate));
  //     history.push({
  //       pathname: '/reseller',
  //       search: `?startdate=${dateFormatIso(selectionRange.startDate)}&enddate=${dateFormatIso(selectionRange.endDate)}`
  //     });
  //     setStartDate(selectionRange.startDate);
  //     setEndDate(selectionRange.endDate);
  //   };

  //   const clearFilter = () => {
  //     setSearchText('');
  //     setStartDate(bulanIniStart);
  //     setEndDate(new Date());
  //     setselectionRange({
  //       startDate: bulanIniStart,
  //       endDate: new Date(),
  //     });
  //     clearPage();
  //     getFormatData(dateFormatIso(bulanIniStart), dateFormatIso(new Date()))
  //     history.push({
  //       pathname: '/reseller'
  //     });
  //     // console.log(startDate);
  //   };

  //   function downloadExcel() {
  //     setDataexcel([]);
  //     setIsDownloading(true);
  //     axios.get(`/api/reseller/download?startdate=${dateFormatIso(startDate)}&enddate=${dateFormatIso(endDate)}`, {
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'x-access-token': ls.get('token')
  //       },
  //     })
  //     .then(function (response) {
  //       // handle success
  //       setDataexcel(response.data.values.data)
  //       // console.log(response);
  //       setIsDownloading(false);
  //       // excelClick();
  //     })
  //     .catch(function (error) {
  //       // handle error
  //       // console.log(error);
  //       setIsDownloading(false);
  //       errorHandler(props, error.response.status);
  //     })
  //   }

  //   function downloadExcel2() {
  //     // setDataexcel([]);
  //     setIsDownloading(true);
  //     axios.get(`/api/reseller/downloadExcel?startdate=${dateFormatIso(startDate)}&enddate=${dateFormatIso(endDate)}`, {
  //       headers: {
  //         // 'Content-Type': 'blob',
  //         'x-access-token': ls.get('token')
  //       },
  //       responseType: 'arraybuffer',
  //     })
  //     .then(function (response) {
  //       // handle success
  //       // setDataexcel(response.data.values.data)
  //       // console.log(response);
  //       fileDownload(response.data, 'reseller.xlsx');
  //       // let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }),
  //       // url = window.URL.createObjectURL(blob)

  //       // window.open(url)
  //       setIsDownloading(false);
  //       // excelClick();
  //     })
  //     .catch(function (error) {
  //       // handle error
  //       // console.log(error);
  //       setIsDownloading(false);
  //       errorHandler(props, error.response.status);
  //     })
  //   }

  useEffect(() => {
    if (dataexcel.length !== 0) {
      downloadExcelRef.current.click();
    }
  }, [dataexcel]);

  return (
    <>
      <Loader show={isLoading} />
      <Loader show={isDownloading} title="Downloading Data" />

      <div className="">

        <div className="row mb-3">
          <div className='col-12 col-md-12 p-2'>
            <h5 className='text-dark'>Pemeriksaan</h5>
          </div>

          <div className='col-12 col-md-4 p-2 d-flex flex-column justify-content-around'>
            {/* <div className='p-3 rounded bg-white drop-shadow h-100 mb-4 d-flex justify-content-between align-items-center'>
              <div className='text-muted' style={{fontSize: 12, fontWeight: 500}}>
                Total Data
                <br/>
                <span className='h2 text-dark'>{totalData}</span>
              </div>
              <div className='rounded-icon'><i className="fa fa-edit fa-lg text-white" aria-hidden="true"></i></div>
            </div> */}
          </div>


          <div className='col-12 col-md-12 p-2'>
            <div className='p-3 rounded bg-white drop-shadow'>
              <div className='d-flex mb-2 flex-column flex-md-row'>

                {/* <input type="text" style={{fontSize: 12, width: '210px'}} className="form-control bg-white" readOnly id="orderid-input" data-toggle="modal" data-target="#exampleModal" value={dateFormat(selectionRange.startDate) + ' - ' + dateFormat(selectionRange.endDate)} /> */}


                <ExcelFile filename="Reseller" element={<button className='btn btn-success mr-auto d-none mb-2' ref={downloadExcelRef} style={{ fontSize: 12 }}><i className="fa fa-file-excel-o" aria-hidden="true"></i> Download Excel</button>}>
                  <ExcelSheet data={dataexcel} name="Reseller">
                    <ExcelColumn label="Waktu Join" value="WaktuJoin" />
                    <ExcelColumn label="ID Reseller" value="IDRESELLER" />
                    <ExcelColumn label="Nama" value="NAMARESELLER" />
                    <ExcelColumn label="Alamat" value="alamatreseller" />
                    <ExcelColumn label="Saldo" value="saldo" />
                    <ExcelColumn label="Tambahan Harga Pribadi" value="TambahanHargaPribadi" />
                    <ExcelColumn label="Tambahan Harga Upline" value="tambahanhargaupline" />
                    <ExcelColumn label="Pendapatan DownLine" value="PendapatanDownLine" />
                    <ExcelColumn label="Aktivitas Terakhir" value="AktivitasTerakhir" />
                    <ExcelColumn label="Alias" value="alias" />
                    <ExcelColumn label="Poin" value="poin" />
                    <ExcelColumn label="Nama Pemilik" value="namapemilik" />
                    <ExcelColumn label="No KTP" value="noktp" />
                    <ExcelColumn label="Tgl Lahir" value="tgllahir" />
                    <ExcelColumn label="Ibu Kandung" value="ibukandung" />
                    <ExcelColumn label="Telp" value="telp" />
                    <ExcelColumn label="Email" value="email" />
                    <ExcelColumn label="Provinsi" value="province_name" />
                    <ExcelColumn label="Kota" value="city_name" />
                    <ExcelColumn label="Kecamatan" value="district_name" />
                    <ExcelColumn label="Kode POS" value="kodepos" />
                  </ExcelSheet>
                </ExcelFile>

                <div className='mr-auto d-flex flex-md-row flex-column'>
                  <Combobox
                    defaultValue={selectedStatus}
                    data={status}
                    onChange={(value) => {
                      setSelectedStatus(value)
                      console.log(value)
                    }}
                  />
                  {/* <input type="text" value={searchText} onChange={(e) => setSearchText(e.target.value)} style={{width: '200px', fontSize: 12}} className="form-control bg-white mr-2 mb-2" placeholder="Search..." /> */}

                  {/* <div className="input-group mb-2" style={{width: '300px'}}>
                    <input type="text" className="form-control bg-white" style={{fontSize: 12}} readOnly data-toggle="modal" data-target="#exampleModal" value={dateFormat(selectionRange.startDate) + ' - ' + dateFormat(selectionRange.endDate)} />
                    {params.has('startdate') && (
                      <div className="input-group-append">
                        <button className="btn btn-outline-danger" onClick={() => clearFilter()} style={{fontSize: 12}}><i className="fa fa-refresh" aria-hidden="true"></i></button>
                      </div>
                    )}
                    <div className="input-group-append">
                      <button className="btn btn-outline-primary" onClick={() => filter()} style={{fontSize: 12}}>Filter</button>
                    </div>
                  </div> */}
                </div>

                {/* <button 
                  className='btn btn-success mb-2' 
                  onClick={() => {
                    downloadExcel();
                  }} 
                  style={{fontSize: 12}}>
                    <i className="fa fa-file-excel-o" aria-hidden="true"></i> Download Excel
                </button> */}
                <button
                  className='btn btn-success mb-2'
                  //   onClick={() => {
                  //     downloadExcel2();
                  //   }} 
                  style={{ fontSize: 12 }}>
                  <i className="fa fa-file-excel-o" aria-hidden="true"></i> Download Excel
                </button>

              </div>

              <table className="table table-nowrap">
                <thead className="table-default">
                  <tr>
                    <th scope="col" className='border-0 text-dark text-center' style={{ width: '100px' }}>ID</th>
                    <th scope="col" className='border-0 text-dark text-center'>Nama PU</th>
                    <th scope="col" className='border-0 text-dark text-center'>Nama PU Alt</th>
                    <th scope="col" className='border-0 text-dark text-center'>No. Daftar</th>
                    <th scope="col" className='border-0 text-dark text-center'>Tgl Daftar</th>
                    <th scope="col" className='border-0 text-dark text-center'>Nama Jenis Daftar</th>
                    <th scope="col" className='border-0 text-dark text-center'>Nama Jenis Produk</th>
                    <th scope="col" className='border-0 text-dark text-center'>Status Reg</th>
                    <th scope="col" className='border-0 text-dark text-center'>Jumlah Produk</th>
                    <th scope="col" className='border-0 text-dark text-center'>Jenis Usaha</th>
                    <th scope="col" className='border-0 text-dark text-center'>Nama LPH</th>
                    <th scope="col" className='border-0 text-dark text-center'>No Urut NDPU</th>
                    <th scope="col" className='border-0 text-dark text-center'>No NDPU</th>
                    <th scope="col" className='border-0 text-dark text-center'>Jenis Daftar</th>
                    <th scope="col" className='border-0 text-dark text-center'>Jenis Produk</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td className='font-weight-bold'>
                          {item.id_reg}
                        </td>

                        <td>{item.nama_pu}</td>
                        <td>{item.nama_pu_alt}</td>
                        <td>{item.no_daftar}</td>
                        <td>{item.tgl_daftar}</td>
                        <td>{item.nama_jenis_daftar}</td>
                        <td>{item.nama_jenis_produk}</td>
                        <td>{item.nama_status_reg}</td>
                        <td>{item.jml_produk}</td>
                        <td>{item.nama_jenis_usaha}</td>
                        <td>{item.nama_lph}</td>
                        <td>{item.no_urut_ndpu}</td>
                        <td>{item.no_ndpu}</td>
                        <td>{item.jenis_daftar}</td>
                        <td>{item.nama_jenis_produk}</td>

                      </tr>
                    )
                  })}
                </tbody>
              </table>
              <p className='text-right'>Total Data {totalData}</p>
              {/* <div className="col">
                {totalData > 100 ? (
                  <Pagination
                    page={page}
                    url="/reseller"
                    getData={getFormatData}
                    totalPage={Math.ceil(totalData / data.length)}
                    handleChangePageNumber={handleChangePageNumber}
                  />
                ) : null}
              </div> */}
            </div>
          </div>


        </div>
      </div>
    </>
  )
}

export default React.memo(Pemeriksaan)
