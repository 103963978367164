import React from 'react'
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { withRouter } from "react-router-dom";
// import { Breakpoint } from 'react-socks';
import iconMenu from './icon-menu.svg';
import './navbar.scss';
import ls from 'local-storage';
import logo from '../../img/logompstore.svg';
import { errorHandler } from '../../../helpers/errorHandler'
import { postLogout } from '../../../services/auth';
import Cookies from 'universal-cookie';

const Navbar = (props) => {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  function handleMenu(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const handleLogout = () => {
    postLogout()
      .then((responseJson) => {
        alert('Logout Success');
        ls.remove('isLogin');
        ls.remove('token');
        const cookies = new Cookies();
        cookies.remove('__bpjph_ct', { path: '/' });
        cookies.remove('__bpjph_rt', { path: '/' });
        props.history.push('/login');
        setAnchorEl(null);
      })
      .catch(function (error) {
        // handle error
        // console.log(error);
        errorHandler(props, error);
      })
  }

  return (
    <>
      <div className={`py-2 px-md-0 px-lg-3 bg-white fixed-top mynavbar`}>
        <div className="container-fluid">
          <div className="align-items-center row">
            <div className="d-inline-block d-lg-none mr-3 ml-4">
              <img src={iconMenu} onClick={props.handleShowMenuMobile} alt="..." />
            </div>
            <div className="col-lg-4 col">
              <span className="h5 font-bold">
                <div className="d-none d-lg-block" style={{ letterSpacing: '-0.7px', marginTop: '-3px' }}>
                  SIHALAL
                </div>
                <div className="d-block d-lg-none" style={{ marginTop: '-2px' }}>
                  <img src={logo} className="img-fluid" style={{ width: '10%' }} alt="..." />
                </div>
              </span>
            </div>
            <div className="col-lg-8 col">
              <div className="d-flex justify-content-end align-items-center">
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={open}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleClose}>Setting</MenuItem>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default withRouter(Navbar)
