import React, { useState, useEffect, createRef } from 'react'
import Loader from '../../components/loader/Loader'
import moment from 'moment'
import ReactExport from 'react-export-excel'
import ls from 'local-storage'
import { ExcelRenderer, OutTable } from "react-excel-renderer";
import axios from "axios";
import DownloadLink from "react-download-link";
import { Icon } from '@material-ui/core'
import { DateRangePicker } from 'react-date-range'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import localeId from 'date-fns/locale/id';
import { useHistory } from "react-router-dom";
import { dateFormatIso, getRupiah } from '../../helpers/helpers';
import Chart from 'chart.js/auto';
import { Line } from 'react-chartjs-2';
import fileDownload from 'js-file-download'
import { errorHandler } from '../../helpers/errorHandler'
import Reseller from '../reseller/Reseller'
import Combobox from "react-widgets/Combobox";
import utm from "../../components/img/logo.png";
import UploadImages from '../../components/uploadFile/uploadFile'

const Register = (props) => {

  const [isLoading, setIsLoading] = useState(false);

  const status = ["Telco", "Minimarket", "Makanan", "Elektronik", "Jasa", "Lainnya"];
  const [selectedStatus, setSelectedStatus] = useState('Pilih Jenis Usaha');

  const [name, setName] = useState('');
  const [alamat, setAlamat] = useState('');
  const [namePemilik, setNamePemilik] = useState('');
  const [alamatPemilik, setAlamatPemilik] = useState('');
  const [idmp, setIdMp] = useState('');

  const [provinsi, setProvinsi] = useState([]);
  const [selectedProvinsi, setSelectedProvinsi] = useState('Pilih Provinsi');

  const [kabupaten, setKabupaten] = useState([]);
  const [selectedKabupaten, setSelectedKabupaten] = useState('Pilih Kabupaten');

  const [kecamatan, setKecamatan] = useState([]);
  const [selectedKecamatan, setSelectedKecamatan] = useState('Pilih Kecamatan');

  const [kota, setKota] = useState([]);
  const [selectedKota, setSelectedKota] = useState('Pilih Kota');

  const [kodePos, setKodePos] = useState('');
  const [nik, setNik] = useState('');
  const [nomor, setNomor] = useState('');
  const [email, setEmail] = useState('');

  const [fileKtp, setFileKtp] = useState('');
  const [fileSelfie, setFileSelfie] = useState('');
  

  useEffect(() => {
    getProvinsi();
  }, [])

  const getProvinsi = () => {
    setIsLoading(true);
    axios.get(`/api/area/provinsi`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(function (response) {
        // handle success
        setProvinsi(response.data.values.data)
        // console.log(response);
        setIsLoading(false);
      })
      .catch(function (error) {
        // handle error
        // console.log(error);
        setIsLoading(false);
        errorHandler(props, error?.response?.status);
      })
  }

  const changeProvinsi = (value) => {
    setIsLoading(true);
    setSelectedProvinsi(value.id_prov)
    axios.get(`/api/area/kabupaten/` + value.id_prov, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(function (response) {
        // handle success
        setKabupaten(response.data.values.data)
        // console.log(response);
        setIsLoading(false);
      })
      .catch(function (error) {
        // handle error
        // console.log(error);
        setIsLoading(false);
        errorHandler(props, error?.response?.status);
      })
  }

  const changeKabupaten = (value) => {
    setIsLoading(true);
    setSelectedKabupaten(value.id_kab);
    axios.get(`/api/area/kecamatan/` + value.id_kab, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(function (response) {
        // handle success
        setKecamatan(response.data.values.data)
        // console.log(response);
        setIsLoading(false);
      })
      .catch(function (error) {
        // handle error
        // console.log(error);
        setIsLoading(false);
        errorHandler(props, error?.response?.status);
      })
  }

  const changeKecamatan = (value) => {
    setIsLoading(true);
    setSelectedKecamatan(value.id_kec);
    axios.get(`/api/area/kelurahan/` + value.id_kec, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(function (response) {
        // handle success
        setKota(response.data.values.data)
        // console.log(response);
        setIsLoading(false);
      })
      .catch(function (error) {
        // handle error
        // console.log(error);
        setIsLoading(false);
        errorHandler(props, error?.response?.status);
      })
  }

  const changeKelurahan = (value) => {
    setIsLoading(true);
    setSelectedKota(value.id_kel);
    setIsLoading(false);
  }

  const registerHandler = (e) => {
    e.preventDefault();
    if (
      name === '' ||
      alamat === '' ||
      namePemilik === '' ||
      alamatPemilik === '' ||
      idmp === '' ||
      selectedStatus === '' || selectedStatus === 'Pilih Jenis Usaha' ||
      selectedProvinsi === '' || selectedProvinsi === 'Pilih Provinsi' ||
      selectedKabupaten === '' || selectedKabupaten === 'Pilih Kabupaten' ||
      selectedKecamatan === '' || selectedKecamatan === 'Pilih Kecamatan' ||
      selectedKota === '' || selectedKota === 'Pilih Kota' ||
      kodePos === '' ||
      nik === 0 || nik === '' ||
      nomor === 0 || nomor === '' ||
      email === '' ||
      fileKtp === '' ||
      fileSelfie === ''
    ) {
      alert('Harap Lengkapi Data');
      return false;
    } else {
      setIsLoading(true);
      axios.post('/api/daftar', {
        name: name,
        alamat: alamat,
        namePemilik: namePemilik,
        alamatPemilik: alamatPemilik,
        idmp: idmp,
        jenisusaha: selectedStatus,
        provinsi: selectedProvinsi,
        kabupaten: selectedKabupaten,
        kecamatan: selectedKecamatan,
        kota: selectedKota,
        kodePos: kodePos,
        nik: nik,
        nomor: nomor,
        email: email,
        fileKtp: fileKtp,
        fileSelfie: fileSelfie
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(function (response) {
          setIsLoading(false);

          alert(response.data.values.message)
        })
        .catch(function (error) {
          console.log(error);
          setIsLoading(false);
          alert('Gagal mendaftar')
        });
    }
  }

  const clearState = () => {
    setName('');
    setAlamat('');
    setNamePemilik('');
    setAlamatPemilik('');
    setIdMp('');
    setSelectedProvinsi('Pilih Provinsi');
    setSelectedKabupaten('Pilih Kabupaten');
    setSelectedKecamatan('Pilih Kecamatan');
    setSelectedKota('Pilih Kota');
    setKodePos('');
    setNik('');
    setNomor('');
    setEmail('');
    setFileKtp('');
    setFileSelfie('');
  }

  const handleChangeKtp = (value) => {
    setFileKtp(value)
  };

  const handleChangeSelfie = (value) => {
    setFileSelfie(value)
  };

  const handleKodePos = (e) => {
    const limit = 5;
    setKodePos(e.target.value.slice(0, limit));
  }

  const handleNik = (e) => {
    const limit = 16;
    setNik(e.target.value.slice(0, limit));
  }

  const handleNomor = (e) => {
    const limit = 14;
    setNomor(e.target.value.slice(0, limit));
  }

  return (
    <>
      <Loader show={isLoading} />

      <div className="container pt-5 text-center d-flex justify-content-center">
        <div className="col-md-5 text-center">
          <img src={utm} width={'100'} className='mb-4' />
          <form method="POST" onSubmit='#'>
            <div className="form-group">
              <input type="text" name="toko" className="form-control" placeholder='Nama Toko' value={name} onChange={e => setName(e.target.value)} required />
            </div>
            <div className="form-group">
              <input type="text" name="alamat_toko" className="form-control" placeholder='Alamat Toko' value={alamat} onChange={e => setAlamat(e.target.value)} required />
            </div>
            <div className="form-group">
              <input type="text" name="nama_pemilik" className="form-control" placeholder='Nama Pemilik' value={namePemilik} onChange={e => setNamePemilik(e.target.value)} required />
            </div>
            <div className="form-group">
              <input type="text" name="alamat_pemilik" className="form-control" placeholder='Alamat Pemilik' value={alamatPemilik} onChange={e => setAlamatPemilik(e.target.value)} required />
            </div>
            <div className="form-group">
              <input type="text" name="idmpstore" className="form-control" placeholder='ID MPStore (MPS000001 / MP0001)' value={idmp} onChange={e => setIdMp(e.target.value)} required />
            </div>
            <div className='form-group'>
              <Combobox
                defaultValue={selectedStatus}
                data={status}
                onChange={(value) => {
                  setSelectedStatus(value)
                }}
              />
            </div>
            <div className='form-group'>
              <Combobox
                defaultValue={selectedProvinsi}
                data={provinsi}
                dataKey='id_prov'
                textField='nama_prov'
                onChange={(value) => {
                  changeProvinsi(value)
                }}
              />
            </div>
            <div className='form-group'>
              <Combobox
                defaultValue={selectedKabupaten}
                data={kabupaten}
                dataKey='id_kab'
                textField='nama_kab'
                onChange={(value) => {
                  changeKabupaten(value)
                }}
              />
            </div>
            <div className='form-group'>
              <Combobox
                defaultValue={selectedKecamatan}
                data={kecamatan}
                dataKey='id_kec'
                textField='nama_kec'
                onChange={(value) => {
                  changeKecamatan(value)
                }}
              />
            </div>
            <div className='form-group'>
              <Combobox
                defaultValue={selectedKota}
                data={kota}
                dataKey='id_kel'
                textField='nama_kel'
                onChange={(value) => {
                  changeKelurahan(value)
                }}
              />
            </div>
            <div className="form-group">
              <input type="number" maxLength={5} minLength={5} name="zipcode" className="form-control" placeholder='69115' value={kodePos} onChange={handleKodePos} required />
            </div>

            <div className="form-group">
              <input type="number" maxLength={16} minLength={16} name="nik" className="form-control" placeholder='Nomor Induk Sesuai KTP' value={nik} onChange={handleNik} required />
            </div>

            <div className="form-group">
              <input type="number" maxLength={13} minLength={10} name="nomor" className="form-control" placeholder='081234567890' value={nomor} onChange={handleNomor} required />
            </div>

            <div className="form-group">
              <input type="text" name="email" className="form-control" placeholder='mpstore@mpstore.co.id' value={email} onChange={e => setEmail(e.target.value)} required />
            </div>

            <div className='form-group'>
              <label> Upload Foto KTP : </label>
              <UploadImages title={'ktp'} nik={nik} onChange={handleChangeKtp} />
            </div>

            <div className='form-group'>
              <label> Upload Foto Selfie : </label>
              <UploadImages title={'selfie'} nik={nik} onChange={handleChangeSelfie} />
            </div>

            <div className="form-group">
              <h6 className="mr-auto text-center"> <input type="checkbox" name="acc" className="mr-3" value="acc" required /> Saya Menyetujui <a href="http://mpulsa.net/syarat-dan-ketentuan">Syarat & Ketentuan</a></h6>
            </div>
            <div className="form-group">
              <button type="submit" onClick={registerHandler} className="btn btn-info btn-block px-4">
                Daftar
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default React.memo(Register)