import ls from 'local-storage'
import Cookies from 'universal-cookie';
import axios from "axios";

let cookie = ls.get('Cookie');
// export const dataList = async () => {
//       const cookie = new Cookies()
//       const URL = 'api/v1/data_list/10010/' + process.env.REACT_APP_ID_LPH;
//       console.log(URL)
//       const res = axios({
//         method: 'GET',
//         url: URL,
//         headers: {
//           'Content-Type': 'application/json',
//           'Accept' : '*/*',
//           'Accept-Encoding' : 'gzip, deflate, br',
//           'Connection' : 'keep-alive',
//         },
//         withCredentials: true,
//       })
//       .then(function (response) {
//         let pushRes = response;
//         return pushRes;
//       })
//       .catch(function (error) {
//         let pushRes = error.response;
//         return pushRes;
//       });
//       return await res;
//     }

export const dataList = async (data) => {
  console.log(cookie);
  const URL = 'api/v1/data_list/10010/' + process.env.REACT_APP_ID_LPHDEV;
  const res = fetch(URL,{
    credentials: 'same-origin',
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
  })
  .then(function (response, request) {
    let pushRes = response.json();
    return pushRes;
  })
  .catch(function (error) {
    let pushRes = error.response;
    return pushRes;
  });
  return await res;
}

export const dataMohon = async () => {
  const URL = 'api/v1/reg/'
}