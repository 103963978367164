import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import PrivateRoute from "./components/privateroute/PrivateRoute";

import Layout from "./components/layout/Layout";

import Login from "./containers/user/Login";
import Register from "./containers/register/Register";
import Dashboard from "./containers/dashboard2/Dashboard";
import Pemeriksaan from "./containers/pemeriksaan/Pemeriksaan";
import NotFound from "./containers/notFound/NotFound";

const App = props => {
  const path = props.location.pathname;
   if (path === "/") {
    return (
      <Switch>
        <Route exact path="/" component={Register} />
      </Switch>
    );
   } else if (path === "/daftar") {
    return (
      <Switch>
        <Route exact path="/daftar" component={Register} />
      </Switch>
    );
   } else {
    return (
      <Switch>
        <Route exact component={NotFound} />
      </Switch>
    )
   }
};

export default withRouter(App);
